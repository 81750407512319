/*jshint globalstrict: true*/
/* jshint node: true */
"use strict";

class SocialMediaManager {

    constructor(options) {
        this.socialMediaDescription = '';
        this.socialMediaLink = '';
        this.socialMediaFbAction = '';
        this.socialMediaEmailString = '';
        this.libraryLink = '';

        if (options.pageSettingsHolder) {
            this.initializeSettings(options.pageSettingsHolder);
        }

        this.initialize();
    }

    initializeSettings(pageSettingsHolder) {
        if (pageSettingsHolder.has('socialMediaDescription')) {
            this.socialMediaDescription = pageSettingsHolder.get('socialMediaDescription');
        }

        if (pageSettingsHolder.has('socialMediaLink')) {
            this.socialMediaLink = pageSettingsHolder.get('socialMediaLink');
        }

        if (pageSettingsHolder.has('socialMediaFbAction')) {
            this.socialMediaFbAction = pageSettingsHolder.get('socialMediaFbAction');
        }

        if (pageSettingsHolder.has('socialMediaEmailString')) {
            this.socialMediaEmailString = pageSettingsHolder.get('socialMediaEmailString');
        }

        if (pageSettingsHolder.has('libraryLink')) {
            this.libraryLink = pageSettingsHolder.get('libraryLink');
        }
    }

    /**
     * opens email client
     */
    mailpage(){
        window.open(this.socialMediaEmailString, '_blank');
    }

    /**
     * opens facebook share
     */
    fbShare() {
        FB.ui({
            method: 'share_open_graph',
            action_type: 'og.shares',
            action_properties: this.socialMediaFbAction
        }, function(response){});
    }

    initialize() {
        console.log('Processing social media');
        let imageLink = this.socialMediaLink ? this.socialMediaLink : window.location.origin + '/wp-content/uploads/2017/12/Twitadd.jpg';
        let url = window.location.href;
        let libraryLink = this.libraryLink ?  window.location.origin + this.libraryLink :  window.location.origin;
        let title = document.title;
        let description =  this.socialMediaDescription ?  this.socialMediaDescription : 'A reading resource from Learning Steward\'s Magic Ladder Library';

        this.socialMediaEmailString = 'mailto:?subject=FYI: ' + title + ' - ' +description;
        this.socialMediaEmailString += '&body=I thought you might be interested in this: ' + title;
        this.socialMediaEmailString += ' '+description+' ('+libraryLink+')';
        this.socialMediaEmailString += '. You can access it here: ' + url;

        let twitter_elms = document.getElementsByClassName('twitter-share-button');
        let twitter_src = 'https://platform.twitter.com/widgets/tweet_button.html?&url='+url+'&via=learningsteward&related=twitterapi%2Ctwitter&text=';
        twitter_src += title+' - '+description+'&hashtags=Learning';
        twitter_src += encodeURI(twitter_src);

        for (let i = 0; i < twitter_elms.length; i++) {
            twitter_elms[i].setAttribute("src", twitter_src);
            console.log(twitter_src);
        }

        description += libraryLink;

        window.fbAsyncInit = function() {
            FB.init({
                appId            : '1674611085893445',
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v2.11'
            });
        };

        (function(d, s, id){
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        this.socialMediaFbAction = JSON.stringify({
            object: {
                'og:url': url,
                'og:title': title,
                'og:description': description,
                'og:image': imageLink
            }
        });

        let social_elms = document.getElementsByClassName('social-media-buttons');
        for (let i = 0; i < social_elms.length; i++) {
            social_elms[i].classList.remove('is-disabled');
        }

        let pin_elems = document.getElementsByClassName('pinterest-button');
        let pin_description = title + ' ' + url +' - '+description;
        for (let i = 0; i < pin_elems.length; i++) {
            pin_elems[i].setAttribute("data-pin-description", pin_description);
            pin_elems[i].setAttribute("data-pin-url", url);
            pin_elems[i].setAttribute("data-pin-media", imageLink);
        }

        let linkedin_elems = document.getElementsByClassName('linkedin-share');
        let linkedin_href = 'https://www.linkedin.com/shareArticle?mini=true&url='+ url +'&';
        linkedin_href += 'submitted-image-url='+ url +'&comment=Test&summary='+ url +' - '+description;
        for (let i = 0; i < linkedin_elems.length; i++) {
            linkedin_elems[i].setAttribute("href", linkedin_href);
        }
    }
}